/**
 * 邮件订阅
 * @param email
 * @param type
 * @returns
 */

const handleEmlSubmit = async (email: any, type: "coupon" | "newsletters" | "member" | "Checkout" | "vsVibeReport" = "coupon", country: "de" | "us" | "jp" = "us", industry?: string) => {
    const { create } = useStrapi<any>();

    try {
        await create("emails", {
            email,
            type,
            country,
            industry,
        });
    } catch (e) {
        return Promise.reject(new Error("your email has not been sent successfully"));
    }
};

export default handleEmlSubmit;

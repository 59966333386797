/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email: any) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
}

/**
 * @param {string} url
 * @returns
 */
export function validateUrl(url: string) {
    return /^(http|https)/.test(url);
}
